$(function(){
    initGlobal();
    init();
});
function init(){
    $(function(){
        if(!initpage) initpage = '';
        var page = initpage;
        if(page){ 
            console.log('BiP: ' + page);
            switch(page){
                case 'material': 
                case 'arbeitsblaetter': 
                case 'elastic': 
                case 'marktplatz': 
                case 'bilder': 
                case 'veranstaltungen': 
                case 'dienstleistungen':
                case 'unterkuenfte': 
                case 'aktuelles': 
                    initCheckAll(page);
                    initFilters();
                    initTableTiles();
                    addListFunctionality();
                    initItemList();
                break;
                case 'arbeitsblaetterdetail':
                case 'materialdetail':
                    actionButtons('umt');
                    refreshDownload();
                break;
                case 'marktplatzdetail':
                    actionButtons('mrk');
                    $('.thumbnail').unbind('click');
                    $('.thumbnail').click(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                break;
                case 'unterkuenftedetail':
                    actionButtons('grp');
                    $('.thumbnail').unbind('mouseover');
                    $('.thumbnail').mouseover(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                    $('.preview.init-galery').unbind('click');
                    $('.preview.init-galery').click(function(){
                        var fotoid = $(this).attr('data-fotoid');
                        lightbox.start($('#' + fotoid)); return false;
                    });
                    initDetailMap();
                break;
                case 'bilderdetail':
                    actionButtons('sbi');
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'veranstaltungendetail':
                    actionButtons('mrk');
                    $('.thumbnail').unbind('click');
                    $('.thumbnail').click(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                break;
                case 'veranstaltungenbeitrag':
                    initActivetoggle('veranstaltungenbeitrag');
                break;
                case 'dienstleistungendetail':
                    actionButtons('mrk');
                    $('.thumbnail').unbind('click');
                    $('.thumbnail').click(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                break;
                case 'dienstleistungenbeitrag':
                    initActivetoggle('dienstleistungenbeitrag');
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'stellvertretungenangebot': 
                case 'stellvertretungensuchende': 
                case 'feststellenangebot': 
                case 'feststellensuchende': 
                case 'nachhilfeangebot': 
                case 'nachhilfesuchende':
                    initCheckAll();
                    initFilters();
                    addListFunctionality();
                    initItemList();
                break;
                case 'stellvertretungenangebotdetail':
                    actionButtons('vik');
                    $('.thumbnail').unbind('click');
                    $('.thumbnail').click(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                break;
                case 'stellvertretungenangebotbeitrag':
                    initPremiumCheckB();
                    initActivetoggle('stellvertretungenangebotbeitrag');
                break;
                case 'stellvertretungensuchendedetail':
                    actionButtons('vik');
                break;
                case 'stellvertretungensuchendebeitrag':
                    initActivetoggle('stellvertretungensuchendebeitrag');
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'feststellenangebotdetail':
                    actionButtons('ste');
                    $('.thumbnail').unbind('click');
                    $('.thumbnail').click(function(){
                        var imgurl = $(this).attr('data-img');
                        $(this).parent().next('.preview').attr('src',imgurl);
                    });
                break;
                case 'feststellenangebotbeitrag':
                    initPremiumCheckB();
                    initActivetoggle('feststellenangebotbeitrag');
                break;
                case 'feststellensuchendedetail':
                    actionButtons('ste');
                break;
                case 'feststellensuchendebeitrag':
                    initActivetoggle('feststellensuchendebeitrag');
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'nachhilfeangebotdetail':
                    actionButtons('nac');
                break;
                case 'nachhilfeangebotbeitrag':
                    initActivetoggle('nachhilfeangebotbeitrag');
                break;
                case 'nachhilfesuchendedetail':
                    actionButtons('nac');
                break;
                case 'nachhilfesuchendebeitrag':
                    initActivetoggle('nachhilfesuchendebeitrag');
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'unterkuenftekarte':
                    initMaps();
                break;
                case 'unterkuenfteowneredit':
                    $('#richtpreis_1, #richtpreis_2, #richtpreis_3').inputFilter(function(value) {
                        return /^\d*$/.test(value);
                    });
                break;
                /*////////////////////////////////////////////////////////////*/
                case 'hilfe':
                    $('.content_item h3').click(function(){
                        var that = $(this);
                        if($(this).hasClass('closed')){
                            $(this).removeClass('closed');
                            $(this).addClass('opened');
                            $(this).next().slideDown('fast', function(){
                                $(that).find('i').removeClass('fa-chevron-right');
                                $(that).find('i').addClass('fa-chevron-down');
                            });
                            
                        } else {
                            $(this).removeClass('opened');
                            $(this).addClass('closed');
                            $(this).next().slideUp('fast', function(){
                                $(that).find('i').removeClass('fa-chevron-down');
                                $(that).find('i').addClass('fa-chevron-right');
                            });
                        }
                    });
                break;
                case 'profil':
                    initform('profil','content_mein_inner');
                    //initImageUploads();
                    $('#passwort').pstrength();
                    $('.profiledelete1').unbind('click');
                    $('.profiledelete1').click(function(){
                        $(this).parent().parent().next().toggle('fast');
                    });
                    $('.profiledelete3').unbind('click');
                    $('.profiledelete3').click(function(){
                        $.ajax({
                            type: "POST",
                            url: "/mein/profildelete",
                            data: "&preventdirect=1",
                            success: function(content) {
                                if(content.match('DELETED OK')){
                                    location.href = "/";
                                }
                            }
                        });
                    });
                break;
                case 'datenschutz':
                    initform('datenschutz','content_mein_inner');
                break;
                case 'benachrichtigungen':
                    initform('benachrichtigungen','content_mein_inner');
                break;

                /*////////////////////////////////////////////////////////////*/
                /*////////////////////////////////////////////////////////////*/
                /*////////////////////////////////////////////////////////////*/
                case 'meinmaterialdownloads': 
                case 'meinmaterialuploads': 
                case 'meinmaterialfavorites': 
                case 'meinbilderdownloads': 
                case 'meinbilderuploads': 
                case 'meinbilderfavorites':
                case 'meinmarktplatzbeitraege':
                case 'meinstellvertretungenangebote':
                case 'meinstellvertretungensuche':
                case 'meinstellvertretungenfavorites':
                case 'meinfeststellenangebote':
                case 'meinfeststellensuche':
                case 'meinnachhilfeangebote':
                case 'meinnachhilfesuche':
                case 'meinveranstaltungen':
                case 'meindienstleistungen':
                case 'meinunterkuenftefavorites': 
                case 'meinunterkuenfte': 
                    addListFunctionality();
                    initItemList();
                break;
                /*////////////////////////////////////////////////////////////*/ 
            }
            //DEFAULT
            initButtons();
            initTooltip();
            initCheckboxes();
            initSelectDate();
            initWeekOverview();
            initDocumentUploads();
            initImageUploads();
        }
    });
};
function initGlobal(){
    $(function(){
        //global
        initMein();
        $('#openlogin').click(function(){ openMeta('login'); });
        $('#openmein').click(function(){ toggleMy(); });
        $('#menubutton').click(function(){ $('#navigation').toggle(); });
        initResize();
        page = 'GLOBAL';
        console.log("BiP: " + page);
     });
}

var resize_timeout = 0;
function initResize(){
    $(window).resize(function() {
        window.clearTimeout(resize_timeout);
        resize_timeout = window.setTimeout(function(){
            if($(window).width() > 1110){
                $('aside .filterbox').show('0');
            }
        }, 500);
    });
}

function initMeta(type){
    if(type){
        switch(type){
            case 'login':
            break;
        }
        $('#submit' + type).click(function(){ submitMeta(type); });        
    } 
    //console.log("BiP: initMeta -> " + type);
};
function initCheckAll(page){
    $('.checkAll').bind('click', function(){
        $(this).parent().parent().find('li div.dvForm_imagecheck').each(function(){
            $(this).removeClass('unchecked');
            $(this).addClass('checked');
            $(this).next().val($(this).attr('data-val'));
        });
        if(!page){
            var cont = $(this).attr('data-cont');
            var act = $(this).attr('data-act');
            console.log(cont + ' ==> ' + act);
            loadItemList(cont,act);
        } else {
            loadItemList(page);
        }
    });
    $('.checkNone').bind('click', function(){
        $(this).parent().parent().find('li div.dvForm_imagecheck').each(function(){
            $(this).removeClass('checked');
            $(this).addClass('unchecked');
            $(this).next().val('');
        });
        if(!page){
            var cont = $(this).attr('data-cont');
            var act = $(this).attr('data-act');
            loadItemList(cont,act);
        } else {
            loadItemList(page);
        }
    });
};
function initTableTiles(){
    $('.layout-tiles').bind('click',{ view: 'tiles' },changeView);
    $('.layout-table').bind('click',{ view: 'table' },changeView);   
}
function actionButtons(rub){
    $('.favorit').unbind('click');
    $('.favorit').click(function(){
        var id = $(this).attr('data-id');
        addFavorites(id,rub,$(this)); 
    });
    $('.mailautor').unbind('click');
    $('.mailautor').click(function(){
        var idautor = $(this).attr('data-autor');
        var detailpath = $(this).attr('data-detailpath');
        openMeta('mailautor',idautor,detailpath);
    });
    $('.report').unbind('click');
    $('.report').click(function(){
        var id = $(this).attr('data-id');
        var type = $(this).attr('data-type');
        openMeta('report',id,type);
    });
    $('.setrating').unbind('click');
    $('.setrating').click(function(){
        var id = $(this).attr('data-id');
        setRating(id,rub,$(this));
    });
}
function initMein(){
    $('#nav_mein li > a').each(function(){
        var page = $(this).attr('data-page');
        var current_page = $('#openmein').attr('data-page');
        if(page == current_page){
            $(this).addClass('selected');
            $(this).parent().parent().show(0);
        }
        if(typeof page !== typeof undefined && page !== false){
            $(this).click(function(){
                loadMy(page);
                $('#openmein').attr('data-page',page);
                $('#nav_mein li > a').removeClass('selected');
                $(this).addClass('selected');
            });
        } else {
            $(this).click(function(){
                $(this).next().toggle('fast');
            });
        }
    });
}
function initPremiumCheckB(){
    console.log('DV-Debug: init');
    $("#agreement").change(function(){
        console.log('DV-Debug: change');
        if($(this).val() > 0){
            $('.premium').css('display','inline-block');
        }else{
            $('.premium').css('display','none');
        }
    });
}
function initFilters(){
    $('.filtertitle').bind('click',function(){
        if($(this).parent().hasClass('opened')){
            if(!$(this).next('ul').hasClass('halbtagefilter') || $(this).next('ul').find('.checked').length < 1){
                $(this).next('ul').find('li > div').not('.checked').hide('fast');
            }
            $(this).parent().removeClass('opened');
            $(this).parent().removeClass('selected');
            if($(this).next('ul').find('li div.checked').length > 0){
                $(this).parent().addClass('selected');
            }
        } else {
            $(this).next('ul').find('> li > div').show('fast');
            $(this).parent().removeClass('selected');
            $(this).parent().addClass('opened');
        }
    });
    $('.subfiltertitle').bind('click',function(){
        if($(this).parent().hasClass('opened')){
            $(this).next('ul').find('li > div').not('.checked').hide('fast');
            $(this).parent().removeClass('opened');
            $(this).parent().removeClass('selected');
            if($(this).next('ul').find('li div.checked').length > 0){
                $(this).parent().addClass('selected');
            }
        } else {
            $(this).next('ul').find('> li > div').show('fast');
            $(this).parent().removeClass('selected');
            $(this).parent().addClass('opened');
        }
    });
    $('.dvForm_datefield').each(function(){
        //$(this).children().first().css('background','#ff0000');
        if($(this).children().first().val().length > 0){
            changedDate($(this).children().first());
        }
    });
    $('.filtertoggle').bind('click',function(){
        $('.filterbox').toggle('fast');
    });
    /*$('.dvForm_datefield').children().first().bind('change',function(){
        console.log('CHANGED');
        if($(this).val() < 1){
           $(this).parent().removeClass('checked');
           $(this).parent().addClass('unchecked');
           $(this).next().val('')
        } else {
           $(this).parent().removeClass('unchecked');
           $(this).parent().addClass('checked');
            $(this).parent().bind('click', function(){
                $(this).children().val('');
                if($(this).children().first().attr('onchange') != ''){
                   eval($(this).children().first().attr('onchange'));
                }
            })
        }
        if($(this).attr('onchange') != ''){
           eval($(this).attr('onchange'));
        } 
    });*/
}
function refreshDownload(){
    $('.downl').click(function(){
        var href = $(this).attr('href').split('/');
        var det = '/' + href[1] + '/' + href[2] + '/' + href[3];
        var that = $(this);
        if(det){
            setTimeout(function(){
                if($(that).closest(".detailbox").length>0){
                    $.ajax({
                        type: "POST",
                        url: det,
                        data:'&ajax=1',
                        success: function(content) {
                            $(that).closest('.detailbox-inner').html(content);
                        }
                    });
                } else {
                    location.reload();
            }
            },1000);  
        }
        
    });
}

function submitEnter(ausfuehren,e){
    var keycode;
    if (window.event) keycode = window.event.keyCode;
    else if (e) keycode = e.which;
    else return true;
    if (keycode === 13){
       eval(ausfuehren);
       return false;
    } else {
       return true;
    }
}
function showOverlay(){
    $('#overlay').fadeTo('fast', 1);
    $('#overlay').css('top',$(window).scrollTop()) ;
    $('#overlay_bg').height($(document).height());
    $('#overlay_bg').fadeTo('fast', 0.9);
}
function adjustOverlay(){
    $('#overlay_bg').height($(document).height());
}
function hideOverlay(){
    $('#overlay').fadeOut('fast');
    $('#overlay_bg').fadeOut('fast');
}
function initform(name,container){
    if($('#' + name + 'form') && $('#' + name + 'submit')){
        $('#' + name + 'submit').click(function(){
            var serialized = $('#' + name + 'form').serialize();
            var url = $('#' + name + 'form').attr('action')
            $('#' + container).html('<div class=\"loader\"><img src=\"' + loader + '\"></div>');
            $.ajax({
                type: 'POST',
                url: url,
                data: serialized,
                success: function(content) {
                    if(content == 'LOGOUT'){
                        location.reload(true);
                    } else {
                        $('#' + container).html(content).promise().done(function(){
                            //initform(name,container);
                        });
                    }
                }
            });
        });
    }
}
function initActivetoggle(name){
    $('#' + name + 'toggle').click(function(){
        if($(this).next().val() < 1){
            $(this).next().val(1);
        } else {
            $(this).next().val(0);                
        }
        $('#' + name + 'form').submit();
    });
}

function changeView(event){
    $('.listcontainer').removeClass('table');
    $('.listcontainer').removeClass('tiles');
    $('.listcontainer').addClass(event.data.view);        
    
};
function toggleMy(){
    $("#mein").slideToggle('slow', function() {
        if($("#mein").is(':visible')){
            $('#openmein i').removeClass('fa-arrow-down');
            $('#openmein i').addClass('fa-arrow-up');              
            // load content
            var page = $('#openmein').attr('data-page');
            loadMy(page);
            
        } else {
            $('#openmein i').removeClass('fa-arrow-up');
            $('#openmein i').addClass('fa-arrow-down');   
            $('#content_mein_inner').html('');
        }
    });       
};

function loadMy(page){
    $('#content_mein_inner').html('<div class=\"loader\"><img src=\"' + loader + '\"></div>');
    $.ajax({
        type: "POST",
        url: '/mein/' + page,
        success: function(content) {
            $('#content_mein_inner').html(content).promise().done(function(){
                ga('set', { page: '/mein/' + page, title: 'Mein Asynchron: ' + page });
                ga('send', 'pageview');
            });
        }
    });
};


function openMeta(type,var1,var2){
    if(!var1) var1 = '';
    if(!var2) var2 = '';
    showOverlay();
    $('#overlay_inner').html('<div class=\"loader\"><img src=\"' + loader + '\"></div>');
    $.ajax({
        type: "POST",
        url: '/meta/' + type,
        data: '&var1=' + var1 + '&var2=' + var2,
        success: function(content) {
            $('#overlay_inner').html(content).promise().done(function(){
                initMeta(type);
                ga('set', { page: '/meta/' + type, title: 'Meta Asynchron: ' + type });
                ga('send', 'pageview');
            });
        }
    });
}
function submitMeta(type){
    var serialized = $('#' + type + 'form').serialize();
    $('#overlay_inner').html('<div class=\"loader\"><img src=\"' + loader + '\"></div>');
    $.ajax({
        type: 'POST',
        url: '/meta/' + type,
        data: serialized,
        success: function(content) {
            if(content == 'META OK'){
                location.reload(true);
            } else {
                $('#overlay_inner').html(content).promise().done(function(){
                    initMeta(type);
                });
            }
        }
    });
}
function addFavorites(id,type,that){
    var listitem = $(that).parents('.item').find('.listmarker');
    if(listitem.hasClass('fa-heart')){
        listitem.removeClass('fa-heart'); 
       listitem.removeClass('red');
        listitem.removeClass('fa');
        listitem.parent().removeClass('dv-tt');
        listitem.parent().removeAttr('data-tooltip');
    }
    $(that).find('.fa-heart').removeClass('red');
    $.ajax({
        type: 'POST',
        url: '/action/favorites/',
        data: '&type=' + type + '&id=' + id,
        success: function(content) {
            if(content == 'FAVORITE ADDED'){
                $(that).find('.fa-heart').addClass('red');
                if(!listitem.hasClass('fa-check')){
                    listitem.removeClass('nomarker')
                    listitem.addClass('fa');
                    listitem.addClass('fa-heart');
                    listitem.addClass('red');
                    listitem.parent().addClass('dv-tt');
                    listitem.parent().attr('data-tooltip',translatejs('Beitrag ist als Favorit markiert'));
                }
                ga('set', { page: '/action/favorites/', title: 'Favorites Asynchron: ' + type + ' - ' + id });
                ga('send', 'pageview');

                initTooltip();
            }
        }
    });
}
function setRating(id,type,that){
    if($('#setrating').length > 0){
        //console.log("unbind " + $('#setrating').length);
        $('#setrating').unbind('mousemove');
        $('#setrating').unbind('click');
        $('#setrating').remove();
    } else {
        //console.log("create");
        $('body').append('<div id="setrating"><div id="setrating_inner"><div class="basestars"><div class="selectstars"></div></div></div></div>');
        var position = $(that).offset();
        var top = position.top + $(that).outerHeight() + 10;
        var left = position.left -($('#setrating').outerWidth()/2 - $(that).outerWidth()/2) + 4;
        $('#setrating').css('top',top).css('left',left).fadeIn(0.7);
        $('#setrating').mousemove(function (e) {
            var swidth = e.pageX-$('#setrating').offset().left;
            $('#setrating .selectstars').css('width',swidth);
        });
        $('#setrating').click(function () {
            var ratingsend =  $('#setrating .selectstars').width()*100/$('#setrating .basestars').width();
            $.ajax({
                type: 'POST',
                url: '/action/setrating/',
                data: '&type=' + type + '&id=' + id + '&rating=' + ratingsend,
                success: function(content) {
                    if(content.match('RATING OK')){
                        var vars = content.split("#|#");
                        var rating = parseInt(vars[1]);
                        $(that).parents('.detailbox').find('.rating').css('width',rating + "%");
                        $(that).parents('.item').find('.rating').css('width',rating + "%");
                        $('#setrating').unbind('mousemove');
                        $('#setrating').unbind('click');
                        $('#setrating').remove();
                    }
                ga('set', { page: '/action/setrating/', title: 'Rating Asynchron: ' + type + ' - ' + id + ' - ' + ratingsend});
                ga('send', 'pageview');
                }
            });
        });
    }
}

function updateMine(mine, id){
    if(mine > 0){
        $('#mat' + id).find('.listmarker').removeClass('nomarker').addClass('fa fa-check green');
    } else {
        return true;
    }
}

var search_timeout = 0;
function timeoutItemList(controller,action,inst){
    if(!inst) inst = 1;
    if(!action) action = '';
    eval(window.clearTimeout(search_timeout));
    eval(search_timeout = window.setTimeout("loadItemList('" + controller + "','" + action + "')", 800));
}
var search_timeout2 = 0;
function timeoutItemList2(controller,action,inst){
    if(!inst) inst = 1;
    if(!action) action = '';
    eval(window.clearTimeout(search_timeout2));
    eval(search_timeout2 = window.setTimeout("loadItemList('" + controller + "','" + action + "')", 800));
}
function initItemList(){
    $('.load-itemlist').each(function(){
        var contr = $(this).attr('data-controller');
        var act = $(this).attr('data-action');
        $(this).click(function(){
            loadItemList(contr,act,1);
        });
        $(window).scroll(function(){
            if ($(window).scrollTop() === $(document).height() - $(window).height()){
                $(window).unbind('scroll');
                loadItemList(contr,act,1);
            }
        });
    });
}
function loadItemList(controller,action,append,paginateroff){
    eval(window.clearTimeout(search_timeout));
    eval(window.clearTimeout(search_timeout2));
    
    if(!action) action = '';
    if(!append) append = 0;
    if(!paginateroff) paginateroff = 0;
    if(append < 1){
        $('#src_' + controller + action + '_offset').val(0);
    }
    var serialized = '';
    if($('#' + controller + action + '-form')){
        serialized = $('#' + controller + action + '-form').serialize();
    }

    if(!$('#' + controller + action + '-loader')){
        $('#' + controller + action + '-container').after('<div id="' + controller + action + '-loader"><div class="loader"></div><input name="' + controller + action + '-offset" type="hidden" value="0"></div>');
    }
    if(!$('#' + controller + action + '-paginator')){
        $('#' + controller + action + '-paginator').fadeIn('fast');
    }

    $('#' + controller + action + '-loader').html('<img src="' + loader + '">').fadeIn('fast');
    if(append < 1){
        $('#' + controller + action + '-container').html('');
    }

    $.ajax({
        type: "POST",
        url: '/' + controller + '/' + action + 'liste',
        data: serialized,
        success: function(content) {
            $('#' + controller + action + '-loader').fadeOut('fast');
            var json_data = $.parseJSON(content);
            
            if(json_data['meta']['query']){
                console.log('dvDebug: QUERY: ' + json_data['meta']['query']);
            }
            if(json_data['meta']['took']){
                $('.time-took').html('(' + (json_data['meta']['took']/1000) + ' s)');
            }
            if(json_data['meta']['aggs']){
                addAggs(json_data['meta']['aggs']);
            }
            if(json_data['meta']['total']){
                $('.total-items').html(json_data['meta']['total']);
            }
            
            $.each(json_data['items'], function(i, item) {
                var row = dvf.template(json_data['template'], item);
                $('#' + controller + action + '-container').append(row);
            });
            initTooltip();
            addListFunctionality();
            if(paginateroff < 1){
                //console.log("dvDebug List-Total PAGINATE: " + $('#' + controller + action + '-container .item').length);
                if(json_data['items'].length > 49){
                    $('#src_' + controller + action + '_offset').val(parseInt($('#src_' + controller + action + '_offset').val())+parseInt($('#src_' + controller + action + '_limit').val()));
                    $(window).scroll(function(){
                        if ($(window).scrollTop() === $(document).height() - $(window).height()){
                            $(window).unbind('scroll');
                            loadItemList(controller,action,1);
                        }
                    });
                } else {
                    if(json_data['items'].length < 50){
                        $(window).unbind('scroll');
                        //$('#' + controller + action + '-loader a').fadeOut('fast').unbind('click');
                        $('#' + controller + action + '-loader').html('');
                        $('#' + controller + action + '-paginator').fadeOut('fast');
                        if(append > 0){
                            $('#' + controller + action + '-container').append('<div class="message_notice">' + translatejs('Es wurden keine weiteren Einträge gefunden.') + '</div>');
                        } 
                    }
                }
            }
            ga('set', { page: '/' + controller + '/' + action + 'liste/' + $('#src_' + controller + action + '_offset').val(), title: 'Liste Asynchron: ' + controller });
            ga('send', 'pageview');
        }
    });
}
function addAggs(aggs){
    $('.aggregations1').html('');
    $.each(aggs['termstats']['buckets'], function(j, term) {
        $('.aggregations1').append('<br>' + $('#src_elastic').val() + ' <b>' + term['key'] + '</b>'); //term[j]['key']
    });
    $('.aggregations2').html('');
    $.each(aggs['sign_bigramms']['buckets'], function(j, term) {
        $('.aggregations2').append('<br>' + $('#src_elastic').val() + ' <b>' + term['key'] + '</b>'); //term[j]['key']
    });
    $('.filter-grades').html('');
    $.each(aggs['grades']['buckets'], function(j, term) {
        $('.filter-grade' + term['key']).html('(' + term['doc_count'] + ')');
    });
    $('.filter-ratings').html('');
    $.each(aggs['ratings']['buckets'], function(j, term) {
        $('.filter-rating' + term['key']).html('(' + term['doc_count'] + ')');
    });
    $('.filter-topics').html('');
    $.each(aggs['topics']['buckets'], function(j, term) {
        $('.filter-topic' + term['key']).html('(' + term['doc_count'] + ')');
    });
    $('.filter-levels').html('');
    $.each(aggs['levels']['buckets'], function(j, term) {
        $('.filter-level' + term['key']).html('(' + term['doc_count'] + ')');
    });
    $('.filter-countries').html('');
    $.each(aggs['countries']['buckets'], function(j, term) {
        $('.filter-country' + term['key']).html('(' + term['doc_count'] + ')');
    });
}

/*
function loadItemList(controller,action,append,paginateroff){
    eval(window.clearTimeout(search_timeout));
    eval(window.clearTimeout(search_timeout2));
    
    if(!action) action = '';
    if(!append) append = 0;
    if(!paginateroff) paginateroff = 0;
    if(append < 1){
        $('#src_' + controller + action + '_offset').val(0);
    }
    var serialized = '';
    if($('#' + controller + action + '-form')){
        serialized = $('#' + controller + action + '-form').serialize();
    }

    if(!$('#' + controller + action + '-loader')){
        $('#' + controller + action + '-container').after('<div id="' + controller + action + '-loader"><div class="loader"></div><input name="' + controller + action + '-offset" type="hidden" value="0"></div>');
    }
    if(!$('#' + controller + action + '-paginator')){
        $('#' + controller + action + '-paginator').fadeIn('fast');
    }

    $('#' + controller + action + '-loader').html('<img src="' + loader + '">').fadeIn('fast');
    if(append < 1){
        $('#' + controller + action + '-container').html('');
    }

    $.ajax({
        type: "POST",
        url: '/' + controller + '/' + action + 'liste',
        data: serialized,
        success: function(content) {
            $('#' + controller + action + '-loader').fadeOut('fast');
            var json_data = $.parseJSON(content);
            $.each(json_data, function(i, item) {
                if(i < 1){
                    if(typeof item === 'object'){
                        if(item['query']){
                            console.log('dvDebug: QUERY: ' + item['query']);
                        }
                        if(item['took']){
                            $('.time-took').html('(' + (item['took']/1000) + ' s)');
                        }
                        if(item['aggs']){
                            $('.aggregations1').html('');
                            $.each(item['aggs']['termstats']['buckets'], function(j, term) {
                                $('.aggregations1').append('<br>' + $('#src_elastic').val() + ' <b>' + term['key'] + '</b>'); //term[j]['key']
                            });
                            $('.aggregations2').html('');
                            $.each(item['aggs']['sign_bigramms']['buckets'], function(j, term) {
                                $('.aggregations2').append('<br>' + $('#src_elastic').val() + ' <b>' + term['key'] + '</b>'); //term[j]['key']
                            });
                            $('.filter-grades').html('');
                            $.each(item['aggs']['grades']['buckets'], function(j, term) {
                                $('.filter-grade' + term['key']).html('(' + term['doc_count'] + ')');
                            });
                            $('.filter-ratings').html('');
                            $.each(item['aggs']['ratings']['buckets'], function(j, term) {
                                $('.filter-rating' + term['key']).html('(' + term['doc_count'] + ')');
                            });
                            $('.filter-topics').html('');
                            $.each(item['aggs']['topics']['buckets'], function(j, term) {
                                $('.filter-topic' + term['key']).html('(' + term['doc_count'] + ')');
                            });
                            $('.filter-levels').html('');
                            $.each(item['aggs']['levels']['buckets'], function(j, term) {
                                $('.filter-level' + term['key']).html('(' + term['doc_count'] + ')');
                            });
                            $('.filter-countries').html('');
                            $.each(item['aggs']['countries']['buckets'], function(j, term) {
                                $('.filter-country' + term['key']).html('(' + term['doc_count'] + ')');
                            });
                        }
                        if(item['total']){
                            $('.total-items').html(item['total']);
                        }
                    }
                } else {
                    $('#' + controller + action + '-container').append(eval('print' + ucfirst(controller + action) + 'Row(item)'));
                }
            });
            initTooltip();
            if(paginateroff < 1){
                //console.log("dvDebug List-Total PAGINATE: " + $('#' + controller + action + '-container .item').length);
                if(json_data.length > 50){
                    $('#src_' + controller + action + '_offset').val(parseInt($('#src_' + controller + action + '_offset').val())+parseInt($('#src_' + controller + action + '_limit').val()));
                    $(window).scroll(function(){
                        if ($(window).scrollTop() === $(document).height() - $(window).height()){
                            $(window).unbind('scroll');
                            loadItemList(controller,action,1);
                        }
                    });
                } else {
                    if(json_data.length < 51){
                        $(window).unbind('scroll');
                        //$('#' + controller + action + '-loader a').fadeOut('fast').unbind('click');
                        $('#' + controller + action + '-loader').html('');
                        $('#' + controller + action + '-paginator').fadeOut('fast');
                        if(append > 0){
                            $('#' + controller + action + '-container').append('<div class="message_notice">' + translatejs('Es wurden keine weiteren Einträge gefunden.') + '</div>');
                        } 
                    }
                }
            }
            ga('set', { page: '/' + controller + '/' + action + 'liste/' + $('#src_' + controller + action + '_offset').val(), title: 'Liste Asynchron: ' + controller });
            ga('send', 'pageview');
        }
    });
}
*/
function clearFilter(that){
    if($(that).next().val() < 1 && !$(that).closest('.filterbox').hasClass('opened') && !$(that).closest('.filterbox > ul > li').hasClass('opened') && !$(that).closest('.filterbox > ul').hasClass('halbtagefilter') && !$(that).closest('.filterbox > ul').hasClass('datefilter') && !$(that).closest('.filterbox > ul').hasClass('dontclearfilter') ){
        $(that).hide('fast');
    }
    if($(that).closest('.filterbox').find('.checked').length < 1){
        if($(that).closest('.filterbox').hasClass('selected')){
            if($(that).closest('.filterbox > ul').hasClass('halbtagefilter')){
                $(that).closest('.filterbox > ul').find('li > div').hide('fast');
            }
            if($(that).closest('.filterbox > ul').hasClass('datefilter')){
                $(that).closest('.filterbox > ul').find('li > div').hide('fast');
            }
            $(that).closest('.filterbox').removeClass('selected');
        }
    } 
}
function changedDate(that){
    if($(that).val().length < 1){
        $(that).next().val('');
        $(that).parent().removeClass('checked');
        $(that).parent().unbind('click');
        if(!$(that).closest('.filterbox').hasClass('opened')){
            $(that).parent().hide('fast');
        }
    } else {
        $(that).parent().addClass('checked');
        $(that).parent().bind('click', function(){
            var action = $(that).attr('data-action');
            var controller = $(that).attr('data-controller');
            $(that).val('');
            $(that).next().val('');
            loadItemList(controller,action);
            changedDate(that);
        });
    }
}


function ucfirst(str) {
  str += '';
  var f = str.charAt(0)
    .toUpperCase();
  return f + str.substr(1);
}
function initTooltip(){
    if(!isMobile.any){
        $('#dv_tooltip').remove();
        $('body').append('<div id="dv_tooltip"><div></div></div>');
        $('.dv-tt').each(function(){
            if(!$(this).hasClass('has-tooltip')){
                $(this).mouseenter(function(){
                    var attr = $(this).attr('data-tooltip');
                    if (typeof attr !== 'undefined' && attr !== false) {
                        var position = $(this).offset();
                        $('#dv_tooltip div').html(attr);
                        var top = position.top + $(this).outerHeight();
                        var left = position.left -($('#dv_tooltip').outerWidth()/2 - $(this).outerWidth()/2);
                        if(left < 0){
                            left = 0;
                            $('#dv_tooltip').css('background-position', ($(this).outerWidth()/2) + 'px top');
                        }
                        $('#dv_tooltip').css('top',top).css('left',left).fadeIn(0.7);
                    }
                });
                $(this).mousemove(function(e){ 
                    $('#dv_tooltip').css('left', e.pageX + 10).css('top', e.pageY + 10).css('display', 'block');
                });
                $(this).mouseleave(function(){
                    $('#dv_tooltip').fadeOut(0, function(){
                        $('#dv_tooltip div').html('');
                    });
                });
                $(this).addClass('has-tooltip');
            }
        });
    }
}
var pre = false;
function checkbox(){
    if(pre === true){
        $('.premium').css('display','none');
        pre = false;
    }else{
        $('.premium').css('display','inline-block');
        pre = true;
    }
}
var button_timeout = 0;
function initButtons(){
    /*
    if(button_timeout < 1){
        clearTimeout(button_timeout)
        button_timeout = setTimeout(switchButtons,5000);
    }
    */
}
function switchButtons(){
    $("#buttons_inner").children().first().clone().appendTo("#buttons_inner");
    $("#buttons_inner").children().first().animate({marginLeft: "-160px"}, 500, function(){
        $("#buttons_inner").children().first().remove();
        button_timeout = setTimeout(switchButtons,4500); 
    });
}
function updateCredits(credits){
    $(".bip-credits").html(credits);
}

function initImageUploads(){
    $('.imageupload').each(function(){
        var instance = $(this).attr('data-instance');
        var url = $(this).attr('data-url');
        var imgpath = $(this).attr('data-imgpath');
        var refresh = $(this).attr('data-refresh');

        $('#inptf' + instance).click(function(){
            $('#fileupload' + instance).click();
        });
        $('#fileupload' + instance).fileupload({
            add: function(e, data) {
                $('#fileupload_notice' + instance).fadeOut('fast');
                var uploadErrors = [];
                var acceptFileTypes = /^image\/(gif|jpe?g|png)$/i;
                if(data.originalFiles[0]['type'].length && !acceptFileTypes.test(data.originalFiles[0]['type'])) {
                    uploadErrors.push(translatejs('Dieser Dateityp kann nicht hochgeladen werden.'));
                }
                if(uploadErrors.length > 0) {
                    $('#fileupload_notice' + instance).html(uploadErrors.join("<br />"));
                    $('#fileupload_notice' + instance).fadeIn('fast');
                } else {
                    data.submit();
                }
            },
            autoUpload: false,
            progressall: function (e, data) {
                if(!$('#fileupload_progress' + instance).is(':visible')){
                    $('#fileupload_progress' + instance).fadeIn('fast');
                }
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('#fileupload_progressfoto01 div span').html(progress + '%');
                $('#fileupload_progressfoto01 div').css('width',progress + '%');
            },
            done: function (e, data) {
                var returns = data.result.split('#|#');
                $('#' + instance).val(returns[0]);
                $('#img_' + instance).css('background-image','url(\'/data/images/' + imgpath + '/' + returns[0] + '\')');
                $('#fileupload_progress' + instance).fadeOut('slow');
                $('#delf' + instance).show('fast');
                if(refresh != ''){
                    $('#' + refresh).css('background-image','url(\'/data/images/' + imgpath + '/' + returns[0] + '\')');
                }
            }
        });
        $('#delf' + instance).unbind('click');
        $('#delf' + instance).click(function(){
            $.ajax({
                type: "POST",
                url: '/action/imagedelete' + url,
                success: function(content) {
                    if(content.match('DELETED')){
                        $('#img_' + instance).css('background-image','none');
                        $('#' + instance).val('');
                        $('#delf' + instance).hide('fast');
                    };
                }
            });
        });
    });
}
function initDocumentUploads(){
    $('.documentupload').each(function(){
        var instance = $(this).attr('data-instance');
        var instancefield = $(this).attr('data-instancefield');
        var instancedel = $(this).attr('data-del');

        $('#inptf' + instance).click(function(){
            $('#fileupload' + instance).click();
        });
        if(instancedel > 0){
            initdelDocumentDel(instance, instancefield);
        }
        $('#fileupload' + instance).fileupload({
            add: function(e, data) {
                data.submit();
            },
            autoUpload: false,
            progressall: function (e, data) {
                if(!$('#fileupload_progress' + instance).is(':visible')){
                    $('#fileupload_progress' + instance).fadeIn('fast');
                }
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('#fileupload_progress' + instance + ' div span').html(progress + '%');
                $('#fileupload_progress' + instance + ' div').css('width',progress + '%');
            },
            done: function (e, data) {
                var returns = data.result.split('#|#');
                $('#' + instancefield).val(returns[0]);

                if(instancedel > 0){
                    $('#upl_' + instance).html(returns[0] + '<i id="inptfdl' + instance +'" class="fa fa-trash uldl"></i>');
                } else {
                    $('#upl_' + instance).html(returns[0]);
                }
                $('#fk_' + instance + '_type').val(returns[1]);
                $('#' + instance + '_size').val(returns[2]);
                $('#fileupload_progress' + instance).fadeOut('slow');
                if(instancedel > 0){
                    initdelDocumentDel(instance, instancefield)
                }
            }
        });
    });
}
function initdelDocumentDel(instance, instancefield){
    $('#inptfdl' + instance).unbind('click');
    $('#inptfdl' + instance).click(function(){
        $('#' + instancefield).val('');
        $('#upl_' + instance).html('');
    });
}
function initCheckboxes(){
    $('.dvForm_imagecheck').unbind('click')
    $('.dvForm_imagecheck').click(function(){
        if($(this).attr('data-val') == $(this).next().val()){
           $(this).removeClass('checked');
           $(this).addClass('unchecked');
           $(this).next().val('')
           $(this).next().change()
           $(this).change()
        } else {
           $(this).removeClass('unchecked');
           $(this).addClass('checked');
           $(this).next().val($(this).attr('data-val'))
           $(this).next().change()
           $(this).change()
        } 
    });
}
function initSelectDate(){
    $('.selectdate').each(function(){
        var name = $(this).attr('data-name');
        var type = $(this).attr('data-type');
        if(type == 2){
            $(this).datetimepicker("destroy");
            $(this).datetimepicker({
                dateFormat: 'dd.mm.yy',
                altField: '#' + name,
                altFormat: 'yy-mm-dd',
                altFieldTimeOnly: false,
                changeMonth: true,
                changeYear: true,
                firstDay: 1
            })
        } else if(type == 1){
            $(this).datepicker("destroy");
            $(this).datepicker({
                dateFormat: 'dd.mm.yy',
                altField: '#' + name,
                altFormat: 'yy-mm-dd',
                altFieldTimeOnly: false,
                changeMonth: true,
                changeYear: true,
                firstDay: 1
            }).blur(function(){
                if($('#' + name + '_hr').val() == ''){
                    $('#' + name).val('');
                }
            })
        }
    });
}
function initWeekOverview(){
    $('.weekoverwiewinput').unbind('click')
    $('.weekoverwiewinput').click(function(){
        if($(this).next().val() < 1 || $(this).next().val() == ''){
            $(this).next().val(1);
            $(this).addClass('active');
        } else {
            $(this).next().val(0);
            $(this).removeClass('active');
        }
    });   
}

function newPasswort(){
    $('#passwort_input').show('fast');
    $('#passwort_input_button').hide('fast');
}
(function($) {
  $.fn.inputFilter = function(inputFilter) {
    return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
    });
  };
}(jQuery));