var dvf = function() { 
    
    var parseTemplate = function(template,values){
        if(template != '' && values){
            var ret = template;
            for (var key in values) {
                // check for IF tag
                if(ret.match(new RegExp('{{#' + key + '}}', 'g'))){
                    if(values[key] != '' && values[key] != false){
                        ret = ret.replace(new RegExp('{{#' + key + '}}|{{/' + key + '}}', 'g'), "");
                    } else {
                        ret = ret.replace(new RegExp('{{#' + key + '}}.*?{{/' + key + '}}', 'g'), "");
                    }
                } 

                ret = ret.replace(new RegExp('{{' + key + '}}', 'g'), values[key]);
            }
            return ret;
        }
    }

    var lazyload_timeout = null;

    var lazyLoad = function(){
        var offset = 400;
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        $('.dv-lazyload:not(.loaded)').each(function(){
            var that = $(this);
            var elementTop = $(that).offset().top;
            var elementBottom = elementTop + $(that).outerHeight();
            //console.log('LazyLoad Object: ' + viewportBottom + ' > ' + elementTop + ' && ' + elementBottom + ' > ' + viewportTop);
            if(viewportBottom > (elementTop-offset) && elementBottom > viewportTop){
                var target = $(that).attr('data-target');
                var folders = $(that).attr('data-lazyload');
                if(folders.match('-')){
                    var sizes = folders.split('-');
                } else {
                    var sizes = [];
                    sizes[0] = folders + '_prev';
                    sizes[1] = folders;
                }
                if(target == 'bg'){
                    var new_bg = $(that).css('background-image').replace(sizes[0],sizes[1]).replace('url(','').replace(')','').replace(/\"/gi, "");;
                    $('<img/>').attr('src', new_bg).on('load', function() {
                        $(this).remove();
                        $(that).css('background-image', 'url(\'' + new_bg + '\'').addClass('loaded').fadeTo(500,1);
                    });

                } else {
                    /*$(that).fadeTo(100,0.3)*/
                    $(that).attr('src', $(that).attr('src').replace(sizes[0],sizes[1])).on('load',function() {
                        $(that).addClass('loaded').fadeTo(300,1);
                    });
                }
            }
        });
    }
    
    var initTooltip = function(){
        $('#dv_tooltip').remove();
        $('body').append('<div id="dv_tooltip"><div></div></div>');
        $('.dv-tt').each(function(){
            if(!$(this).hasClass('has-tooltip')){
                $(this).mouseenter(function(){
                    var attr = $(this).attr('data-tooltip');
                    if (typeof attr !== 'undefined' && attr !== false) {
                        var position = $(this).offset();
                        $('#dv_tooltip div').html(attr);
                        var top = position.top + $(this).outerHeight();
                        var left = position.left -($('#dv_tooltip').outerWidth()/2 - $(this).outerWidth()/2);
                        if(left < 0){
                            left = 0;
                            $('#dv_tooltip').css('background-position', ($(this).outerWidth()/2) + 'px top');
                        }
                        $('#dv_tooltip').css('top',top).css('left',left).fadeIn(0.7);
                    }
                });
                $(this).mousemove(function(e){ 
                    $('#dv_tooltip').css('left', e.pageX + 10).css('top', e.pageY + 10).css('display', 'block');
                });
                $(this).mouseleave(function(){
                    $('#dv_tooltip').fadeOut(0, function(){
                        $('#dv_tooltip div').html('');
                    });
                });
                $(this).addClass('has-tooltip');
            }
        });
    }

    ////////////////////////////////////////////////////////////////////////////
    // PUBLIC //////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    return {
        initLazyload: function() {
            $(function(){
                lazyLoad();
                $(window).resize(function() {
                    window.clearTimeout(lazyload_timeout);
                    lazyload_timeout = window.setTimeout(function(){
                        lazyLoad();
                    }, 300);
                });
                $(window).scroll(function() {
                    window.clearTimeout(lazyload_timeout);
                    lazyload_timeout = window.setTimeout(function(){
                        lazyLoad();
                    }, 300);
                });
            });
        },
        initTooltip: function() {
            initTooltip();
        },
        template: function(template,values) {
            return parseTemplate(template,values);
        },
    }
}();