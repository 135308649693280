/*******************************************************************************/
function addListFunctionality(){
    $('.list-item').each(function(){
        if(!$(this).hasClass('has-functionality')){
            /**** add rating **************************************************/
            $(this).find('.add-rating').each(function(){
                var rating = $(this).attr('data-rating');
                var html = generateRating(rating);
                $(this).html(html);
            });
            /**** add niveau **************************************************/
            $(this).find('.add-niveau').each(function(){
                var niveau = $(this).attr('data-niveau');
                var html = generateNiveau(niveau);
                $(this).html(html);
            });
            /**** add availability *********************************************/
            $(this).find('.add-weekoverview').each(function(){
                var weekoverview = $(this).attr('data-weekoverview');
                var html = generateWeekoverview(weekoverview);
                $(this).html(html);
            });
            /**** add weekoverview *********************************************/
            $(this).find('.add-availability').each(function(){
                var availability = $(this).attr('data-availability');
                var type = $(this).attr('data-availability-type');
                var html = generateAvailability(availability,type);
                $(this).html(html);
            });
            /**** add opendetail **********************************************/
            if($(this).hasClass('opendetail')){
                openDetail(this);
            }
            /**** add events **************************************************/
            $(this).on('mouseenter', function(){
                if($(this).parent().hasClass('tiles')){
                    $(this).find('.detailoverlay').slideDown('fast')
                }
            }).on('mouseleave', function(){
                if($(this).parent().hasClass('tiles')){
                    $(this).find('.detailoverlay').slideUp('fast')
                }
            });

            $(this).find('.item_inner').on('click', function(){
                cleanContent();
                var url = $(this).find('.item-anchor').attr('href');
                if($(this).parent().children().last().hasClass('detailbox')){
                    var detail = $(this).parent().find('.detailbox');
                    if(detail.is(':visible')){
                        detail.slideUp('fast');
                    } else {
                        closeSameRow(this);
                        detail.slideDown('fast');
                    }
                } else {
                    closeSameRow(this);
                    $('<div class="detailbox"><div class="detailbox-inner"><div class="box"><div class="loader"><img src="/dist/loading.gif"></div></div></div></div>').appendTo($(this).parent()).slideDown('fast');
                    var that = $(this);
                    $.ajax({
                        type: "POST",
                        url: url,
                        data:'&ajax=1',
                        success: function(content) {
                            $(that).parent().find('.detailbox-inner').html(content);
                            ga('set', { page: url, title: 'Detailansicht Asynchron' });
                            ga('send', 'pageview');
                        }
                    });
                }
                return false;
            }); 
            /**** mark ********************************************************/
            $(this).addClass('has-functionality');
            initTooltip();
        }
    });    
}
function generateRating(rating){
    var ret = '';
    var ratingperc = Math.floor(rating*20);
    ret += '<div class="rating-box">';
    ret += '    <div class="rating" style="width:' + ratingperc + '%"></i>';
    ret += '</div>';
    return ret;
}
function generateNiveau(niveau){
    var ret = '';
    ret += '<div class="niveau" data-tooltip="' + niveau + '">';
    for(var i=1; i<=3; i++){
        if(4-niveau >= i){
            ret += '<i class="fa fa-square active' + i + '"></i>';
        } else {
            ret += '<i class="fa fa-square"></i>';                
        }
    }
    ret += '</div>';
    return ret;
}
function generateAvailability(availabilities, type){
    var steV;
    var availability = availabilities.split("##");
    var ret = '';
    ret += '<div class="availability">';
    for(var i=0; i<availability.length; i++){
        var parts = availability[i].split("|");
        if(type === 'steV'){
            steV = generateWeekoverview(parts[4],1);
        }else{
            steV = '';
        }
        ret += '<a class="period dv-tt" data-tooltip="' + parts[2] + ' bis ' + parts[3] + '<br> ' + steV  + '" style=\"left:' + parts[0] + 'px;width:' + parts[1] + 'px\"></a>';
    }
    ret += '</div>';
    return ret;
}
function generateWeekoverview(available,escape){
    if(available){
        var halbtage = available.split(",");
        var ret = '';
        ret += '<div class="weekoverview">';
        for(var i=0; i<10; i++){
            if(halbtage[i] > 0){
                ret += '<i class="active"></i>';
            } else {
                ret += '<i></i>';                
            }
        }
        ret += '</div>';
        if(escape > 0){
            return ret.replace(/\"/g,'');
        } else {
            return ret;
        }
    }
}
function addHandlers(ret,row){
    $(ret).on('mouseenter', function(){
                    if($(this).parent().hasClass('tiles')){
                        $(this).find('.detailoverlay').slideDown('fast')
                    }
                });
    $(ret).on('mouseleave', function(){
                    if($(this).parent().hasClass('tiles')){
                        $(this).find('.detailoverlay').slideUp('fast')
                    }
                });
    $(row).on('click', function(){
        cleanContent();
        var url = $(this).find('.item-anchor').attr('href');
        if($(this).parent().children().last().hasClass('detailbox')){
            var detail = $(this).parent().find('.detailbox');
            if(detail.is(':visible')){
                detail.slideUp('fast');
            } else {
                closeSameRow(this);
                detail.slideDown('fast');
            }
        } else {
            closeSameRow(this);
            $('<div class="detailbox"><div class="detailbox-inner"><i class="fa fa-chevron-up"></i><div class="loader"><img src="/pub/img/loading.gif"></div></div></div>').appendTo($(this).parent()).slideDown('fast');
            var that = $(this);
            $.ajax({
                type: "POST",
                url: url,
                data:'&ajax=1',
                success: function(content) {
                    $(that).parent().find('.detailbox-inner').html(content);
                    ga('set', { page: url, title: 'Detailansicht Asynchron' });
                    ga('send', 'pageview');
                }
            });
        }
        return false;
    }); 
}
function closeSameRow(that){
    if($(that).parent().parent().hasClass('tiles')){
        var left = $(that).parent().position().left;
        if(left < 200){
            $(that).parent().next().find('.detailbox').hide('0');
            $(that).parent().next().next().find('.detailbox').hide('0');
        }
        if(left > 200 && left < 500){
            $(that).parent().prev().find('.detailbox').hide('0');
            $(that).parent().next().find('.detailbox').hide('0');            
        }
        if(left > 500){
            $(that).parent().prev().find('.detailbox').hide('0');
            $(that).parent().prev().prev().find('.detailbox').hide('0');            
        }
    }
}
function openDetail(ret){
    var url = $(ret).find('.item-anchor').attr('href');
    $('<div class="detailbox"><div class="detailbox-inner"><i class="fa fa-chevron-up"></i><div class="loader"><img src="/pub/img/loading.gif"></div></div></div>').appendTo($(ret)).slideDown('fast');
    $.ajax({
        type: "POST",
        url: url,
        data:'&ajax=1',
        success: function(content) {
            $(ret).find('.detailbox-inner').html(content);
            ga('set', { page: url, title: 'Detailansicht Asynchron' });
            ga('send', 'pageview');
        }
    });
    return false;
}
function cleanContent(){
    $('#setrating').unbind('mousemove');
    $('#setrating').unbind('click');
    $('#setrating').remove();
};